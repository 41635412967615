'use strict';

Gri.module({
  name: 'carousel-delta',
  ieVersion: null,
  $el: $('.carousel-delta'),
  container: '.carousel-delta',
  fn: function () {

    const $this = this.$el;
    $this.owlCarousel({
      items: 1,
      dots: true,
      nav: false,
      lazyLoad: true
    });

  }
});
